

















































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue'; // @ is an alias to /src
import Footer from '@/components/Footer.vue'; // @ is an alias to /src
import BannerImages from '@/components/BannerImages.vue';
import TimelineCard from '@/components/TimelineCard.vue';

import imageOver from '@/mixins/imageOver';
import mobileUtils from '@/mixins/mobileUtils';

@Component({
	components: {
		Footer,
		BannerImages,
		TimelineCard
	},
	mixins: [imageOver, mobileUtils],
	data() {
		return {
			images: [
				{
					src: require('../assets/back/back5.webp')
				},
				{
					src: require('../assets/back/back6.webp')
				}
			]
		};
	},
	computed: {
		projects() {
			return this.$store.getters.projects;
		}
	},
	methods: {
		startEnd(index: number): string {
			return index % 2 === 1 ? 'start' : 'end';
		},
		isPersonal(type: string): string {
			return type === 'Personal' ? '#0277BD' : '#b21b57';
		}
	},
	created() {
		this.$store.commit('changeCurrentTab', this.$route.name);
	}
})
export default class Home extends Vue {}
