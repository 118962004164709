



































import { Component, Vue } from 'vue-property-decorator';

@Component({
	computed: {},
	methods: {
		goTwitter(): void {
			window.open('https://twitter.com/visancal');
		},
		goInstagram(): void {
			window.open('https://www.instagram.com/visancal/');
		},
		goLinkedin(): void {
			window.open('https://www.linkedin.com/in/vsanjaime/');
		},
		goGithub(): void {
			window.open('https://github.com/visancal');
		}
	},
	components: {}
})
export default class Header extends Vue {}
